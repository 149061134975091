<template>
  <Data :closeAndSave="true" :disableMenu="true" :shadow="false" :showMenu="false" @close="onCloseAndSave">
    <v-card class="pa-5 pa-md-10 mx-auto" max-width="800px" elevation="2">
      <v-row class="px-5" align="center" justify="center" no-gutters>
        <v-col cols="12" class="pb-5">
          <h1 class="font-weight-medium mb-0">
            {{ $t('two_factor_auth.two_factor_auth_heading') }}
          </h1>
        </v-col>

        <v-col cols="12" class="pb-5">
          <h2 class="font-weight-medium mb-0 text-center">
            {{ $t('two_factor_auth.enter_pin_heading_heading') }}
          </h2>
        </v-col>

        <v-col cols="12" class="pt-0 px-0 px-sm-8 px-md-16 text-center">
          <span class="subtitle-1 font-weight-regular">
            {{ $t('two_factor_auth.enter_pin_heading_text') }}
          </span>
        </v-col>

        <v-col cols="12" class="py-0 text-center" style="max-width: 20rem">
          <InputPIN ref="inputPin" v-model="pinCode" @confirmPin="confirmPin" />
        </v-col>

        <v-col cols="12" class="pt-0 pb-3 text-center">
          <v-btn color="primary" min-width="10rem" x-large :loading="isConfirmingPIN" @click="confirmPin">
            {{ $t('two_factor_auth.submit_pin') }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="pt-0 pb-5 text-center">
          <v-btn text @click="sendPinToPhone" class="font-weight-regular primary--text text-decoration-underline">
            {{ $t('two_factor_auth.didnt_receive_pin') }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="pt-0 pb-0 text-center">
          <v-btn
            color="#7d8593"
            text
            @click="onChangePhoneNumber"
            class="font-weight-regular text-decoration-underline"
          >
            {{ $t('two_factor_auth.change_mobile_phone') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </Data>
</template>

<script>
import { ContractService } from '@/services/ContractService.js'
import { mapGetters } from 'vuex'
import { WorkflowHelper } from '@/helpers/WorkflowHelper.js'
import InputPIN from '@/components/InputPIN.vue'
import { getErrorResponseMessage } from '@/common/reusable/errorResponse'
import { approveContractAsRegisteredUser } from '@/features/contract/services/contractService'

export default {
  name: 'contractDocumentSign2FASubmit',
  components: {
    InputPIN,
  },
  data() {
    return {
      pinCode: '',
      isConfirmingPIN: false,
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      myIdentity: 'myIdentity',
    }),
    contractId() {
      return this.$route.params.contract_id
    },
    workspaceId() {
      return this.$route.params.workspace_id
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      ContractService.detail(this.$route.params.contract_id, this.$route.params.workspace_id).then(
        (response) => {
          this.$store.commit('setContract', response)

          if (WorkflowHelper.isMobileReadyForPIN(this.contract) && this.$route.query.pin) {
            return this.sendPinToPhone()
          }

          if (typeof this.$route.params.receivedPinCode !== 'undefined') {
            this.autoConfirmPin(this.$route.params.receivedPinCode)
          }
        },
        () => {
          this.$notification.error(this.$t('contract.not_loaded'))
        },
      )
    },
    sendPinToPhone() {
      ContractService.sendPhoneAuthorized({ mobile: this.myIdentity.mobile }, this.contract.id, this.myIdentity.id)
        .then((response) => {
          if ('undefined' === typeof response.smsPin) {
            this.$notification.success(this.$t('account.send_pin.ok'))
            return
          }

          this.autoConfirmPin(response.smsPin)
        })
        .catch((err) => {
          this.$notification.error(getErrorResponseMessage(err.response?.data || err))
        })
    },
    autoConfirmPin(pinCode) {
      setTimeout(() => {
        this.pinCode = pinCode
      }, 200)

      setTimeout(() => {
        this.confirmPin()
      }, 200)
    },
    confirmPin() {
      this.isConfirmingPIN = true

      if (!this.myIdentity) {
        this.isConfirmingPIN = false

        return this.$router.push({
          name: 'documentsDetail',
          params: {
            workspace_id: this.$route.params.workspace_id,
            contract_id: this.$route.params.contract_id,
          },
        })
      }

      if ('' === this.pinCode) {
        this.isConfirmingPIN = false

        return
      }

      ContractService.sendPinAuthorized(this.pinCode, this.contract.id, this.myIdentity.id).then(
        () => {
          WorkflowHelper.redirectAfterVerifiedSmsPin({
            contract: this.contract,
            routeParams: {
              workspace_id: this.$route.params.workspace_id,
              contract_id: this.contract.id,
            },
            routeQuery: this.$route.query.progress ? { progress: 1 } : null,
            autoapprove: () =>
              approveContractAsRegisteredUser({
                contractId: this.contract.id,
                signIdentityId: this.myIdentity.id,
                note: null,
              }),
            redirectToCompleted: () =>
              this.$router.push({
                name: 'contractsCollectionCreated',
                params: {
                  contractId: this.contract.id,
                  workspaceId: this.$route.params.workspace_id,
                },
              }),
          })

          this.isConfirmingPIN = false
        },
        (errorCode) => {
          if (429 === errorCode) {
            this.$notification.error(this.$t('contract.errors.pin_limit'))
            this.redirectToPhone()
            return
          }
          this.isConfirmingPIN = false
          this.$notification.error(
            403 === errorCode ? this.$t('contract.errors.access_2fa') : this.$t('contract.errors.invalid_pin'),
          )
        },
      )
    },
    redirectToPhone() {
      this.$router.push({
        name: 'contractDocumentSign2FAStart',
        params: {
          contract_id: this.contract.id,
        },
      })
    },
    onChangePhoneNumber() {
      return this.$router.push({
        name: 'contractDocumentSign2FAStart',
      })
    },
    onCloseAndSave() {
      return this.$router.push({
        name: 'documentsDetail',
        params: {
          workspace_id: this.workspaceId,
          contract_id: this.contractId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
